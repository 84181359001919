import React, { useEffect, useState } from "react";

import { useDropzone } from "react-dropzone";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";

import { Container } from "@mui/system";
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import GavelIcon from "@mui/icons-material/Gavel";
import ReceiptIcon from '@mui/icons-material/Receipt';
import ForwardIcon from '@mui/icons-material/Forward';
import HowToRegIcon from "@mui/icons-material/HowToReg";
import DownloadIcon from "@mui/icons-material/Download";
import CarRepairIcon from '@mui/icons-material/CarRepair';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import FolderZipIcon from "@mui/icons-material/FolderZip";
import MinorCrashIcon from "@mui/icons-material/MinorCrash";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { Backdrop, Box, Button, CircularProgress, Drawer, IconButton, ImageList, ImageListItem, ImageListItemBar, Paper, Table, TableCell, TableRow, Tooltip, Typography } from "@mui/material";

import LoadingSpinner from "../layout/LoadingSpinner";
import PracticeAssignModal from "./PracticeAssignModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import PracticesMessagesDrawer from "./PracticesMessagesDrawer";
import PracticesStatesHistoryModal from "./PracticesStatesHistoryModal";
import PracticesDetailRepairerInfoModal from "./PracticesDetailRepairerInfoModal";
import PracticesDetailStatusChangeSelect from "./PracticesDetailStatusChangeSelect";
import useGetPracticeCompleteData from "../../hooks/practices/useGetPracticeCompleteData";

const PracticesDetail = ({ user, practiceCode }) => {
  const axiosPrivate = useAxiosPrivate();
  const [searchParams, setSearchParams] = useSearchParams();
  const all = useGetPracticeCompleteData(practiceCode);
  const [data, setData] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [files, setFiles] = useState([]);
  const [users, setUsers] = useState([]);
  const [progresses, setProgresses] = useState([]);
  const [additionalInfos, setAdditionalInfos] = useState({});
  const [liquidatedImports, setLiquidatedImports] = useState({});
  const [loadedCurrentUserFiles, setLoadedCurrentUserFiles] = useState([]);
  const [loadedOtherUserFiles, setLoadedOtherUserFiles] = useState([]);
  const [openStatesHistoryModal, setOpenStatesHistoryModal] = useState(false);
  const [openRepairerInfoModal, setOpenRepairerInfoModal] = useState(false);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingBackdrop, setLoadingBackdrop] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);

  useEffect(() => {
    const openMessageDrawer = searchParams.get("toggle_message_drawer");

    if (openMessageDrawer) setOpen(true);

    if (all) {
      if (all.practice) setData(all.practice || {});

      if (all.practice_attachments)
        setAttachments(all.practice_attachments || []);

      if (all.practice_users) setUsers(all.practice_users || []);

      if (all.practice_progresses) setProgresses(all.practice_progresses || []);

      if (all.practice_additional_infos)
        setAdditionalInfos(all.practice_additional_infos || {});

      if (all.practice_liquidated_imports)
        setLiquidatedImports(all.practice_liquidated_imports || {});
    }
  }, [all, data, attachments, users, progresses]);

  useEffect(() => {
    if (
      attachments &&
      attachments.length > 0 &&
      (loadedCurrentUserFiles.length === 0 || loadedOtherUserFiles.length === 0)
    ) {
      const loadFiles = async () => {
        setLoadingFiles(true);
        const filePromises = attachments.map((attachment) =>
          obtainFile(attachment)
        );
        const filesData = await Promise.all(filePromises);
        const currentUserFiles = filesData.filter(
          (fileData) => fileData.is_my_file === true
        );
        const otherUserFiles = filesData.filter(
          (fileData) => fileData.is_my_file === false
        );
        setLoadedCurrentUserFiles(currentUserFiles);
        setLoadedOtherUserFiles(otherUserFiles);
        setLoadingFiles(false);
      };
      loadFiles();
    }
  }, [attachments]);

  ////////////////////////DROPZONE/////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length === 0) {
      window.alert(
        `Puoi caricare massimo ${35 - attachments.length} files! (${35 - attachments.length
        } rimanenti su 35 file totali per pratica)`
      );
      setFiles([]);
      return;
    }

    setFiles((prevFiles) => {
      const newFiles = [
        ...prevFiles,
        ...acceptedFiles.map((file) => ({
          id: Date.now() + Math.random(),
          name: file.name,
          size: file.size,
          preview: URL.createObjectURL(file),
          file,
        })),
      ];

      handleAddFiles(newFiles);
      return newFiles;
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: attachments.length === 35,
    accept: {
      "application/pdf": [],
      "application/msword": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel":
        [],
      "image/png": [],
      "image/jpeg": [],
    },
    maxFiles: 35 - attachments.length,
  });

  ////////////////////////API FILES///////////////////////////////////////////////////////////////////////////////////////////////////////////

  const obtainFile = async (file) => {
    try {
      const response = await axiosPrivate.get(
        `/Practice_Attachments/get_by_id_and_practice_code?practice_code=${practiceCode}&practice_attachment_id=${file.id}`
      );
      if (response) {
        const dataFile = response.data.result;
        const isImageRequest =
          response.data.result.content_type.startsWith("image/");

        dataFile.file = `data:${dataFile.content_type};base64,${dataFile.file}`;
        if (isImageRequest) {
          dataFile.type = "image";
        } else {
          dataFile.type = "document";
        }
        return dataFile;
      }
    } catch (err) {
      console.error("Errore durante la richiesta:", err);
      if (!err?.response) {
      } else if (err.response?.status === 400) {
        window.alert("Username o password mancante!");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert("Errore. Riprova");
      }
      return null;
    }
  };

  const handleDownloadAll = async () => {
    setLoadingBackdrop(true);
    try {
      const response = await axiosPrivate.get(
        `/Practice_Attachments/download_all?practice_code=${practiceCode}&title=${data["practices.title"]}`,
        {
          responseType: "blob",
        }
      );

      if (response) {
        setLoadingBackdrop(false);
        // Crea un oggetto Blob utilizzando direttamente response.data
        const blob = new Blob([response.data], {
          type: response.headers["content-type"], // Imposta il tipo di contenuto
        });

        const blobURL = URL.createObjectURL(blob);

        const downloadLink = document.createElement("a");
        downloadLink.href = blobURL;
        downloadLink.download = `${data["practices.title"]}.zip`; // Nome file per il download
        downloadLink.style.display = "none";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        URL.revokeObjectURL(blobURL); // Libera l'URL creato per il blob
      }
    } catch (err) {
      setLoadingBackdrop(false);
      console.error("Errore durante il download:", err);
      window.alert("Errore durante il download. Riprova.");
    }
  };

  const handleAddFiles = async (files) => {
    setLoadingBackdrop(true);

    const formData = new FormData();

    formData.append("practice_code", practiceCode);

    if (files.length !== 0) {
      for (let i = 0; i < files.length; i++) {
        formData.append("practice_attachments", files[i].file);
      }
    }

    try {
      const upload = await axiosPrivate.post(
        "/Practice_Attachments/create_multiple_with_file",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (upload) {
        setFiles([]);
        window.location.reload();
      }
    } catch (err) {
      setLoadingBackdrop(false);
      console.error("Errore durante la richiesta:", err);
      if (!err?.response) {
        window.alert("Errore. Riprova");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert("Errore. Riprova");
      }
    }
  };

  const handleRemoveFile = async (fileId) => {
    setLoadingBackdrop(true);
    try {
      const response = await axiosPrivate.delete(
        `/Practice_Attachments/delete?id=${fileId}`
      );
      if (response) {
        window.location.reload();
      }
    } catch (err) {
      setLoadingBackdrop(false);
      console.error("Errore durante la richiesta:", err);
      if (!err?.response) {
        window.alert("Errore. Riprova");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else if (err.response?.status === 404) {
        window.alert(
          "Non puoi eliminare questo file caricato da un altro utente"
        );
      } else {
        window.alert("Errore. Riprova");
      }
    }
  };

  ////////////////////////API ACCETTA / RIFIUTA / DEASSEGNA PRATICA //////////////////////////////////////////////////////////////////////////////////////////////////

  const handleAcceptPractice = async () => {
    if (window.confirm("Stai prendendo in carico la pratica. Procedere?")) {
      setLoadingBackdrop(true);
      setLoading(true);
      try {
        const response = await axiosPrivate.post(
          "/Practice_Progresses/create",
          {
            practice_code: practiceCode,
            practice_state_id: 2,
          }
        );
        if (response) {
          window.location.reload();
        }
      } catch (err) {
        setLoadingBackdrop(false);
        setLoading(false);
        console.error("Errore durante la richiesta:", err);
        if (!err?.response) {
          window.alert("Errore. Riprova");
        } else if (err.response?.status === 401) {
          window.alert("Non sei autorizzato");
        } else {
          window.alert("Errore. Riprova");
        }
      }
    }
  };

  const handleRefusePractice = async () => {
    if (
      window.confirm(
        "Stai rifiutando la pratica. Non potrai più visualizzarla. Procedere?"
      )
    ) {
      setLoadingBackdrop(true);
      setLoading(true);
      try {
        const response = await axiosPrivate.post(
          "/Practice_Users/suspend_self_record",
          {
            practice_code: practiceCode,
          }
        );
        if (response) {
          window.location.href = "/area_perito/le_mie_pratiche";
        }
      } catch (err) {
        setLoadingBackdrop(false);
        setLoading(false);
        console.error("Errore durante la richiesta:", err);
        if (!err?.response) {
          window.alert("Errore. Riprova");
        } else if (err.response?.status === 401) {
          window.alert("Non sei autorizzato");
        } else {
          window.alert("Errore. Riprova");
        }
      }
    }
  };

  const handleRevokePractice = async () => {
    if (
      window.confirm(
        `Stai revocando pratica al perito ${data["intermediary.business_name"]}. Procedere?`
      )
    ) {
      setLoadingBackdrop(true);
      try {
        const response = await axiosPrivate.post(
          "/Practice_Users/suspend_record",
          {
            user_code: data["intermediary.code"],
            practice_code: practiceCode,
          }
        );
        if (response) {
          window.location.reload();
        }
      } catch (err) {
        setLoadingBackdrop(false);
        console.error("Errore durante la richiesta:", err);
        if (!err?.response) {
          window.alert("Errore. Riprova");
        } else if (err.response?.status === 401) {
          window.alert("Non sei autorizzato");
        } else {
          window.alert("Errore. Riprova");
        }
      }
    }
  };

  ////////////////////////FUNZIONI DI SUPPORTO///////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleDownloadSingle = async (file) => {
    try {
      const encodedFile = file.file;
      const contentType = file["content_type"];
      const base64Data = encodedFile.split(",")[1]; // Rimuovi il prefisso "data:image/png;base64,"

      // Decodifica base64 in un Uint8Array
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length)
        .fill()
        .map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);

      const blob = new Blob([byteArray], { type: contentType });
      const blobURL = URL.createObjectURL(blob);

      // Creazione del link per il download
      const downloadLink = document.createElement("a");
      downloadLink.href = blobURL;
      downloadLink.download = file.filename;
      downloadLink.style.display = "none";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      URL.revokeObjectURL(blobURL);
    } catch (err) {
      console.error("Errore durante il download:", err);
      window.alert("Errore durante il download. Riprova.");
    }
  };

  const handleConvertTimestamp = (timestamp, type) => {
    const date = new Date(timestamp + "Z"); // Forza l'interpretazione UTC

    // Controlla se la data è valida
    if (isNaN(date.getTime())) {
      console.error("Timestamp non valido:", timestamp);
      return "Data non disponibile";
    }

    // Formatta la data
    let formattedDate = new Intl.DateTimeFormat(
      "it-IT",
      type === "sm"
        ? {
          timeZone: "Europe/Rome",
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }
        : {
          timeZone: "Europe/Rome",
          weekday: "long",
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }
    ).format(date);

    // Capitalizza la prima lettera di ogni parola (giorno e mese)
    formattedDate = formattedDate
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    // Sostituisci "Alle Ore" con "alle ore"
    formattedDate = formattedDate.replace("Alle Ore", "alle");

    return formattedDate;
  };

  ////////////////////////RENDER PAGINA///////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: 10000,
        }}
        open={loadingBackdrop}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <Box
        sx={{
          position: "fixed",
          bottom: -25,
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 5,
          zIndex: 10000,
        }}
      >
        <Button
          fullWidth
          variant="contained"
          startIcon={
            <ExpandLessIcon
              sx={{ scale: "150%", transform: open && "rotate(180deg)" }}
            />
          }
          onClick={toggleDrawer(!open)}
          sx={{
            fontSize: "14pt",
            color: open && "#005ca9",
            background: open && "white",
            // lineHeight: 1,
            px: 1,
          }}
        >
          Messaggi pratica
        </Button>
        <Drawer
          anchor="bottom"
          open={open}
          onClose={toggleDrawer(false)}
          sx={{
            "&.MuiDrawer-root .MuiDrawer-paper": {
              width: isMobile ? "95%" : "75%",
              mx: "auto",
              mt: 16,
              background: "#005ca9",
              height: "80%",
              p: 2,
              pt: 0,
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{ mx: "auto", color: "white", fontStyle: "italic" }}
          >
            Messaggi della pratica "{data["practices.title"]}"
          </Typography>
          {open && (
            <PracticesMessagesDrawer
              practiceCode={practiceCode}
              toggleDrawer={toggleDrawer}
            />
          )}
        </Drawer>
      </Box>
      <Container component={Paper} sx={{ pt: 1, px: 0, pb: 3, mb: 12 }}>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: isMobile ? "100%" : "60%", mr: !isMobile && 2 }}>
            <Table
              sx={{
                boxShadow: 5,
                borderRadius: 2,
                mb: 2,
              }}
            >
              <TableRow>
                <TableCell>Identificativo pratica</TableCell>
                <TableCell sx={{ wordBreak: "break-all" }}>
                  <Typography
                    variant={isMobile ? "subtitle1" : "h5"}
                    color="primary"
                  >
                    <b>{data["practices.title"]}</b>
                  </Typography>
                </TableCell>
              </TableRow>
              {isMobile && (
                <TableRow>
                  <TableCell>
                    Stato pratica
                    <Typography
                      display="block"
                      variant="caption"
                      sx={{ mt: 2 }}
                    >
                      <i>Ultimo aggiornamento</i>
                    </Typography>
                    {(user === "Intermediary" || user === "Controller") &&
                      data["practice_last_status"] !== "Liquidata" && (
                        <Typography
                          display="block"
                          variant="caption"
                          sx={{ mt: 2 }}
                        >
                          <i>Azione</i>
                        </Typography>
                      )}
                  </TableCell>
                  <TableCell>
                    <Button
                      fullWidth
                      sx={{
                        mt: -1,
                        height: "100%",
                        color: "white",
                        background:
                          data["practice_last_status"] === "Nuova"
                            ? "#42c4f5"
                            : data["practice_last_status"] === "Presa in carico"
                              ? "#42a5f5"
                              : data["practice_last_status"] ===
                                "Richiesta danni inviata"
                                ? "purple"
                                : data["practice_last_status"] ===
                                  "Attesa conti perito"
                                  ? "goldenrod"
                                  : data["practice_last_status"] ===
                                    "Inviata stima di parte"
                                    ? "#85d016"
                                    : data["practice_last_status"] ===
                                      "Attesa conti perizia integrativa"
                                      ? "tan"
                                      : data["practice_last_status"] ===
                                        "Attesa documentazione riparazioni"
                                        ? "palevioletred"
                                        : data["practice_last_status"] ===
                                          "Attesa fattura riparazioni"
                                          ? "violet"
                                          : data["practice_last_status"] ===
                                            "Inviata documentazione per pagamento"
                                            ? "orange"
                                            : data["practice_last_status"] ===
                                              "Trattativa con liquidatore"
                                              ? "darkorchid"
                                              : data["practice_last_status"] === "Liquidata"
                                                ? "green"
                                                : data["practice_last_status"] === "Contestata"
                                                  ? "red"
                                                  : data["practice_last_status"] === "Dismesso mandato"
                                                    ? "crimson"
                                                    : data["practice_last_status"] === "Contenzioso" &&
                                                    "orangered",
                      }}
                      onClick={() => setOpenStatesHistoryModal(true)}
                    >
                      {data["practice_last_status"] === "Nuova" ? (
                        <NewReleasesIcon />
                      ) : data["practice_last_status"] === "Presa in carico" ? (
                        <HowToRegIcon />
                      ) : data["practice_last_status"] ===
                        "Richiesta danni inviata" ? (
                        <>
                          <DriveFileMoveIcon />
                          <AccountBalanceIcon />
                        </>
                      ) : data["practice_last_status"] ===
                        "Attesa conti perito" ? (
                        <>
                          <PersonSearchIcon />
                          <HourglassTopIcon />
                        </>
                      ) : data["practice_last_status"] ===
                        "Inviata stima di parte" ? (
                        <>
                          <MinorCrashIcon />
                          <ForwardIcon />
                        </>
                      ) : data["practice_last_status"] ===
                        "Attesa conti perizia integrativa" ? (
                        <>
                          <PersonSearchIcon />
                          <AddIcon />
                        </>
                      ) : data["practice_last_status"] ===
                        "Attesa documentazione riparazioni" ? (
                        <>
                          <HourglassTopIcon />
                          <CarRepairIcon />
                        </>
                      ) : data["practice_last_status"] ===
                        "Attesa fattura riparazioni" ? (
                        <>
                          <HourglassTopIcon />
                          <ReceiptIcon />
                        </>
                      ) : data["practice_last_status"] ===
                        "Inviata documentazione per pagamento" ? (
                        <>
                          <RequestQuoteIcon />
                          <ForwardIcon />
                        </>
                      ) : data["practice_last_status"] ===
                        "Trattativa con liquidatore" ? (
                        <>
                          <CurrencyExchangeIcon />
                          <AccountBalanceIcon />
                        </>
                      ) : data["practice_last_status"] === "Liquidata" ? (
                        <DoneIcon sx={{ fontSize: 40 }} />
                      ) : data["practice_last_status"] === "Contestata" ? (
                        <CloseIcon />
                      ) : data["practice_last_status"] === "Dismesso mandato" ? (
                        <PersonOffIcon />
                      ) : (
                        data["practice_last_status"] === "Contenzioso" && (
                          <GavelIcon />
                        )
                      )}
                      {data["practice_last_status"]}
                      {data["practice_last_status"] === "Liquidata" &&
                        liquidatedImports["liquidated_import"] && (
                          <>
                            <br />
                            Importo: €{liquidatedImports["liquidated_import"]}
                          </>
                        )}
                    </Button>
                    <Typography
                      display="block"
                      variant="caption"
                      align="center"
                      sx={{ mt: 1 }}
                    >
                      <i>
                        <b>
                          {handleConvertTimestamp(
                            data["practice_last_status_update"]
                          )}
                        </b>
                      </i>
                    </Typography>
                    {user === "Intermediary" ? (
                      data["practice_last_status"] === "Nuova" ? (
                        loading ? (
                          <Box sx={{ mt: -2, mb: -3 }}>
                            <LoadingSpinner />
                          </Box>
                        ) : (
                          <Box sx={{ display: "flex", mt: 3 }}>
                            <Button
                              size="small"
                              variant="contained"
                              color="success"
                              sx={{ width: "50%", mr: 0.5 }}
                              onClick={handleAcceptPractice}
                            >
                              Accetta
                            </Button>
                            <Button
                              size="small"
                              variant="contained"
                              color="error"
                              sx={{ width: "50%", ml: 0.5 }}
                              onClick={handleRefusePractice}
                            >
                              Rifiuta
                            </Button>
                          </Box>
                        )
                      ) : (
                        <>
                          {data["practice_last_status"] !== "Liquidata" && (
                            <PracticesDetailStatusChangeSelect
                              practiceCode={practiceCode}
                              currentState={data["practice_last_status"]}
                            />
                          )}
                        </>
                      )
                    ) : (
                      user === "Controller" &&
                      (data["practice_last_status"] === "Nuova" &&
                        data["intermediary.code"] === null ? (
                        <Button
                          fullWidth
                          variant="contained"
                          color="success"
                          sx={{ mt: 3 }}
                          onClick={() => setOpenAssignModal(true)}
                        >
                          Assegna pratica a perito
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          variant="contained"
                          color="error"
                          sx={{ mt: 3 }}
                          onClick={handleRevokePractice}
                        >
                          Revoca pratica al perito
                        </Button>
                      ))
                    )}
                  </TableCell>
                </TableRow>
              )}
              {user !== "Repairer" && (
                <>
                  <TableRow>
                    <TableCell>Caricata da</TableCell>
                    <TableCell>
                      <Typography
                        color="primary"
                        sx={{
                          display: "flex",
                          "&:hover": {
                            cursor: "pointer",
                            textDecoration: "underline",
                          },
                        }}
                        onClick={() => {
                          if (user === "Intermediary")
                            setOpenRepairerInfoModal(true);
                          else if (user === "Controller")
                            window.open(
                              `/area_controller/utenti/riparatori/${data["repairer.code"]}`,
                              "_blank"
                            );
                        }}
                      >
                        <b>{data["repairer.business_name"]}</b>
                        <PersonSearchIcon sx={{ ml: 1 }} />
                      </Typography>
                    </TableCell>
                    {openRepairerInfoModal && (
                      <PracticesDetailRepairerInfoModal
                        open={openRepairerInfoModal}
                        onClose={() => setOpenRepairerInfoModal(false)}
                        userCode={data["repairer.code"]}
                      />
                    )}
                  </TableRow>
                  {data["intermediary.code"] !== null &&
                    (user === "Controller" || user === "Admin") && (
                      <TableRow>
                        <TableCell>Assegnata a</TableCell>
                        <TableCell>
                          <Typography
                            color="primary"
                            sx={{
                              display: "flex",
                              "&:hover": {
                                cursor: "pointer",
                                textDecoration: "underline",
                              },
                            }}
                            onClick={() =>
                              window.open(
                                `/area_controller/utenti/periti/${data["intermediary.code"]}`,
                                "_blank"
                              )
                            }
                          >
                            <b>{data["intermediary.business_name"]}</b>
                            <PersonSearchIcon sx={{ ml: 1 }} />
                          </Typography>
                        </TableCell>
                        {openRepairerInfoModal && (
                          <PracticesDetailRepairerInfoModal
                            open={openRepairerInfoModal}
                            onClose={() => setOpenRepairerInfoModal(false)}
                            practiceData={data}
                          />
                        )}
                      </TableRow>
                    )}
                  {!isMobile &&
                    (user === "Intermediary" || user === "Controller") &&
                    data["practice_last_status"] !== "Liquidata" && (
                      <>
                        <TableCell sx={{ verticalAlign: "middle" }}>
                          Azione
                        </TableCell>
                        <TableCell>
                          {user === "Intermediary" &&
                            data["practice_last_status"] === "Nuova" ? (
                            loading ? (
                              <Box sx={{ mt: -2, mb: -3 }}>
                                <LoadingSpinner />
                              </Box>
                            ) : (
                              <Box sx={{ display: "flex" }}>
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="success"
                                  sx={{ width: "50%", mr: 0.5 }}
                                  onClick={handleAcceptPractice}
                                >
                                  Accetta
                                </Button>
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="error"
                                  sx={{ width: "50%", ml: 0.5 }}
                                  onClick={handleRefusePractice}
                                >
                                  Rifiuta
                                </Button>
                              </Box>
                            )
                          ) : user === "Intermediary" ? (
                            <PracticesDetailStatusChangeSelect
                              practiceCode={practiceCode}
                              currentState={data["practice_last_status"]}
                            />
                          ) : (
                            user === "Controller" &&
                            (data["practice_last_status"] === "Nuova" &&
                              data["intermediary.code"] === null ? (
                              <Button
                                fullWidth
                                variant="contained"
                                color="success"
                                onClick={() => setOpenAssignModal(true)}
                              >
                                Assegna pratica a perito
                              </Button>
                            ) : (
                              <Button
                                fullWidth
                                variant="contained"
                                color="error"
                                onClick={handleRevokePractice}
                              >
                                Revoca pratica al perito
                              </Button>
                            ))
                          )}
                        </TableCell>
                      </>
                    )}
                </>
              )}
              <TableRow>
                <TableCell>Targa veicolo</TableCell>
                <TableCell>
                  <b>{data["practices.insured_plate_number"]}</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Cliente assicurato</TableCell>
                <TableCell>
                  <b>{data["practices.insured_name"]}</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Tipologia sinistro</TableCell>
                <TableCell>
                  <b>{data["practice_types.value"]}</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Luogo sinistro</TableCell>
                <TableCell>
                  <b>{additionalInfos["accident_location"] || "Sconosciuto"}</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Data sinistro</TableCell>
                <TableCell>
                  <b>
                    {additionalInfos["accident_date"]
                      ? handleConvertTimestamp(additionalInfos["accident_date"])
                      : "Sconosciuta"}
                  </b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Data creazione</TableCell>
                <TableCell>
                  {handleConvertTimestamp(data["practices.created_at"])}
                </TableCell>
              </TableRow>
            </Table>
          </Box>
          {!isMobile && (
            <Box sx={{ width: "40%" }}>
              <Typography variant="h6" color="primary">
                Stato pratica
              </Typography>
              <Button
                fullWidth
                sx={{
                  display: "block",
                  height: attachments.length !== 0 ? "70%" : "84%",
                  color: "white",
                  boxShadow: 5,
                  fontSize: "30pt",
                  borderRadius: 2,
                  background:
                    data["practice_last_status"] === "Nuova"
                      ? "#42c4f5"
                      : data["practice_last_status"] === "Presa in carico"
                        ? "#42a5f5"
                        : data["practice_last_status"] ===
                          "Richiesta danni inviata"
                          ? "purple"
                          : data["practice_last_status"] ===
                            "Attesa conti perito"
                            ? "goldenrod"
                            : data["practice_last_status"] ===
                              "Inviata stima di parte"
                              ? "#85d016"
                              : data["practice_last_status"] ===
                                "Attesa conti perizia integrativa"
                                ? "tan"
                                : data["practice_last_status"] ===
                                  "Attesa documentazione riparazioni"
                                  ? "palevioletred"
                                  : data["practice_last_status"] ===
                                    "Attesa fattura riparazioni"
                                    ? "violet"
                                    : data["practice_last_status"] ===
                                      "Inviata documentazione per pagamento"
                                      ? "orange"
                                      : data["practice_last_status"] ===
                                        "Trattativa con liquidatore"
                                        ? "darkorchid"
                                        : data["practice_last_status"] === "Liquidata"
                                          ? "green"
                                          : data["practice_last_status"] === "Contestata"
                                            ? "red"
                                            : data["practice_last_status"] === "Dismesso mandato"
                                              ? "crimson"
                                              : data["practice_last_status"] === "Contenzioso" &&
                                              "orangered",
                }}
                onClick={() => setOpenStatesHistoryModal(true)}
              >
                {data["practice_last_status"] === "Nuova" ? (
                  <NewReleasesIcon fontSize="30pt" />
                ) : data["practice_last_status"] === "Presa in carico" ? (
                  <HowToRegIcon fontSize="30pt" />
                ) : data["practice_last_status"] ===
                  "Richiesta danni inviata" ? (
                  <>
                    <DriveFileMoveIcon fontSize="30pt" />
                    <AccountBalanceIcon fontSize="30pt" />
                  </>
                ) : data["practice_last_status"] ===
                  "Attesa conti perito" ? (
                  <>
                    <PersonSearchIcon fontSize="30pt" />
                    <HourglassTopIcon fontSize="30pt" />
                  </>
                ) : data["practice_last_status"] ===
                  "Inviata stima di parte" ? (
                  <>
                    <MinorCrashIcon fontSize="30pt" />
                    <ForwardIcon fontSize="30pt" />
                  </>
                ) : data["practice_last_status"] ===
                  "Attesa conti perizia integrativa" ? (
                  <>
                    <PersonSearchIcon fontSize="30pt" />
                    <AddIcon fontSize="30pt" />
                  </>
                ) : data["practice_last_status"] ===
                  "Attesa documentazione riparazioni" ? (
                  <>
                    <HourglassTopIcon fontSize="30pt" />
                    <CarRepairIcon fontSize="30pt" />
                  </>
                ) : data["practice_last_status"] ===
                  "Attesa fattura riparazioni" ? (
                  <>
                    <HourglassTopIcon fontSize="30pt" />
                    <ReceiptIcon fontSize="30pt" />
                  </>
                ) : data["practice_last_status"] ===
                  "Inviata documentazione per pagamento" ? (
                  <>
                    <RequestQuoteIcon fontSize="30pt" />
                    <ForwardIcon fontSize="30pt" />
                  </>
                ) : data["practice_last_status"] ===
                  "Trattativa con liquidatore" ? (
                  <>
                    <CurrencyExchangeIcon fontSize="30pt" />
                    <AccountBalanceIcon fontSize="30pt" />
                  </>
                ) : data["practice_last_status"] === "Liquidata" ? (
                  <DoneIcon fontSize="30pt" />
                ) : data["practice_last_status"] === "Contestata" ? (
                  <CloseIcon fontSize="30pt" />
                ) : data["practice_last_status"] === "Dismesso mandato" ? (
                  <PersonOffIcon fontSize="30pt" />
                ) : (
                  data["practice_last_status"] === "Contenzioso" && (
                    <GavelIcon fontSize="30pt" />
                  )
                )}
                <Typography
                  sx={{
                    lineHeight: 1,
                    fontSize: "28pt",
                    fontWeight: "bold",
                    my: 2,
                  }}
                >
                  {data["practice_last_status"]}
                  {data["practice_last_status"] === "Liquidata" &&
                    liquidatedImports["liquidated_import"] && (
                      <>
                        <br />
                        <br />
                        Importo: €{liquidatedImports["liquidated_import"]}
                      </>
                    )}
                </Typography>
              </Button>
              <Typography variant="caption" sx={{ mt: 1 }}>
                <i>
                  Ultimo aggiornamento:{" "}
                  {handleConvertTimestamp(data["practice_last_status_update"])}
                </i>
              </Typography>
              {attachments.length !== 0 && (
                <Button
                  fullWidth
                  size="small"
                  variant="contained"
                  onClick={handleDownloadAll}
                  startIcon={
                    <Box sx={{ height: 25 }}>
                      <DownloadIcon />
                      <FolderZipIcon />
                    </Box>
                  }
                  sx={{ mt: "3%" }}
                  disabled={
                    user === "Intermediary" &&
                    data["practice_last_status"] === "Nuova"
                  }
                >
                  Scarica tutti gli allegati
                </Button>
              )}
            </Box>
          )}
        </Box>
        {openAssignModal && (
          <PracticeAssignModal
            open={openAssignModal}
            onClose={() => setOpenAssignModal(false)}
          />
        )}
        {openStatesHistoryModal && (
          <PracticesStatesHistoryModal
            open={openStatesHistoryModal}
            onClose={() => setOpenStatesHistoryModal(false)}
            practiceCode={practiceCode}
          />
        )}
        {attachments.length !== 0 && isMobile && (
          <Button
            fullWidth
            variant="contained"
            onClick={handleDownloadAll}
            startIcon={
              <Box sx={{ height: 25 }}>
                <DownloadIcon />
                <FolderZipIcon />
              </Box>
            }
          >
            Scarica tutti gli allegati
          </Button>
        )}
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" color="primary">
            File allegati
          </Typography>
          <Box sx={{ width: "100%", boxShadow: 5, borderRadius: 2, pb: 1 }}>
            {loadingFiles ? (
              <>
                <LoadingSpinner />
              </>
            ) : attachments.length !== 0 ? (
              <>
                <Typography
                  color="primary"
                  sx={{ mb: loadedOtherUserFiles.length !== 0 && -2, pt: 2 }}
                >
                  Allegati{" "}
                  {user === "Repairer"
                    ? "del perito"
                    : user === "Intermediary"
                      ? "del riparatore"
                      : user === "Controller" && "della pratica"}
                </Typography>
                {loadedOtherUserFiles.length !== 0 ? (
                  <ImageList
                    variant="woven"
                    cols={isMobile ? 2 : 5}
                    gap={12}
                    sx={{
                      background: "transparent",
                      borderTop: "2px solid #ddd",
                      borderBottom: "2px solid #ddd",
                      p: 2,
                    }}
                  >
                    {loadedOtherUserFiles.map((file, index) => (
                      <Box key={index}>
                        <ImageListItem
                          sx={{
                            height: 200,
                            boxShadow: 5,
                            borderRadius: 2,
                            "&:hover": {
                              border: "5px solid #005ca9",
                              cursor: "pointer",
                            },
                          }}
                          onClick={() => handleDownloadSingle(file)}
                        >
                          <Box sx={{ position: "absolute", top: 0, right: 3 }}>
                            <Tooltip
                              title={
                                user === "Repairer"
                                  ? "File caricato dal perito (non puoi eliminarlo)"
                                  : user === "Intermediary" &&
                                  "File caricato dal riparatore (non puoi eliminarlo)"
                              }
                              followCursor
                              arrow
                            >
                              <IconButton
                                size="small"
                                edge="end"
                                sx={{
                                  color: "#aaa",
                                  "&:hover": {
                                    background: "rgb(255, 255, 255, 0.8)",
                                    color: "rgb(0, 255, 0)",
                                  },
                                }}
                              >
                                <Typography
                                  fontSize="15pt"
                                  fontWeight="bold"
                                  sx={{ mr: 1 }}
                                >
                                  {user === "Repairer"
                                    ? "P"
                                    : user === "Intermediary" && "R"}
                                </Typography>
                              </IconButton>
                            </Tooltip>
                          </Box>
                          {file.type === "document" ? (
                            <>
                              <InsertDriveFileIcon
                                sx={{
                                  mx: "auto",
                                  fontSize: "100px",
                                  color: "#42a5f5",
                                }}
                              />
                              <br />
                              <Typography
                                sx={{
                                  color: "#42a5f5",
                                  "&:hover": {
                                    "text-decoration": "underline",
                                  },
                                }}
                              >
                                File{" "}
                                {file.filename.split(".").pop().toUpperCase()}
                              </Typography>
                            </>
                          ) : (
                            <img
                              src={file.file}
                              alt={`${index}`}
                              style={{ borderRadius: 5 }}
                            />
                          )}
                          <ImageListItemBar
                            sx={{
                              background: "rgba(0, 0, 0, 0.8)",
                              borderBottomLeftRadius: 5,
                              borderBottomRightRadius: 5,
                            }}
                            title={file.filename}
                            subtitle={
                              <>
                                caricato il{" "}
                                {handleConvertTimestamp(file.created_at, "sm")}
                              </>
                            }
                          />
                        </ImageListItem>
                      </Box>
                    ))}
                  </ImageList>
                ) : (
                  "Nessuno"
                )}
                {user !== "Controller" && user !== "Admin" && (
                  <>
                    <Typography
                      color="primary"
                      sx={{
                        mb: loadedCurrentUserFiles.length !== 0 && -2,
                        pt: 2,
                      }}
                    >
                      I miei allegati
                    </Typography>
                    {loadedCurrentUserFiles.length !== 0 ? (
                      <ImageList
                        variant="woven"
                        cols={isMobile ? 2 : 5}
                        gap={12}
                        sx={{
                          background: "transparent",
                          borderTop: "2px solid #ddd",
                          p: 2,
                        }}
                      >
                        {loadedCurrentUserFiles.map((file, index) => (
                          <Box key={index}>
                            <ImageListItem
                              sx={{
                                height: 200,
                                boxShadow: 5,
                                borderRadius: 2,
                                "&:hover": {
                                  border: "5px solid #005ca9",
                                  cursor: "pointer",
                                },
                              }}
                              onClick={() => handleDownloadSingle(file)}
                            >
                              <Box
                                sx={{ position: "absolute", top: 0, right: 3 }}
                              >
                                <Tooltip title="Cancella" followCursor arrow>
                                  <IconButton
                                    size="small"
                                    edge="end"
                                    sx={{
                                      color: "#aaa",
                                      "&:hover": {
                                        background: "rgb(255, 255, 255, 0.8)",
                                        color: "rgb(255, 0, 0)",
                                      },
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveFile(file.id);
                                    }}
                                  >
                                    <CloseIcon sx={{ fontSize: "25pt" }} />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                              {file.type === "document" ? (
                                <>
                                  <InsertDriveFileIcon
                                    sx={{
                                      mx: "auto",
                                      fontSize: "100px",
                                      color: "#42a5f5",
                                    }}
                                  />
                                  <br />
                                  <Typography
                                    sx={{
                                      color: "#42a5f5",
                                      "&:hover": {
                                        "text-decoration": "underline",
                                      },
                                    }}
                                  >
                                    File{" "}
                                    {file.filename
                                      .split(".")
                                      .pop()
                                      .toUpperCase()}
                                  </Typography>
                                </>
                              ) : (
                                <img
                                  src={file.file}
                                  alt={`${index}`}
                                  style={{ borderRadius: 5 }}
                                />
                              )}
                              <ImageListItemBar
                                sx={{
                                  background: "rgba(0, 0, 0, 0.8)",
                                  borderBottomLeftRadius: 5,
                                  borderBottomRightRadius: 5,
                                }}
                                title={file.filename}
                                subtitle={
                                  <>
                                    caricato il{" "}
                                    {handleConvertTimestamp(
                                      file.created_at,
                                      "sm"
                                    )}
                                  </>
                                }
                              />
                            </ImageListItem>
                          </Box>
                        ))}
                      </ImageList>
                    ) : (
                      "Nessuno"
                    )}
                  </>
                )}
              </>
            ) : (
              <Typography variant="caption">Nessun file allegato</Typography>
            )}
            {!loadingFiles &&
              user !== "Controller" &&
              user !== "Admin" &&
              !(
                user === "Intermediary" &&
                data["practice_last_status"] === "Nuova"
              ) &&
              (attachments.length < 35 ? (
                <>
                  <Typography variant="h6" color="primary" sx={{ mt: 4 }}>
                    {attachments.length !== 0
                      ? "Documentazione e foto aggiuntive"
                      : "Aggiungi documentazione e foto"}{" "}
                    ({35 - attachments.length} files rimanenti)
                  </Typography>
                  <Box
                    {...getRootProps()}
                    sx={{
                      border: "2px solid #ddd",
                      py: 3,
                      m: 1,
                      textAlign: "center",
                      cursor: "pointer",
                      borderRadius: 2,
                      "&:hover": {
                        background: attachments.length >= 35 && "#e5f4ff",
                      },
                      background: attachments.length >= 35 && "#ddd",
                    }}
                  >
                    <input {...getInputProps()} />
                    <Typography
                      variant="h6"
                      sx={{
                        marginBottom: 2,
                        color: attachments.length >= 35 && "grey",
                      }}
                    >
                      {attachments.length >= 35 ? (
                        <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                          Limite di 35 file raggiunto.
                        </Typography>
                      ) : isMobile ? (
                        "Clicca qui per selezionare i files"
                      ) : isDragActive ? (
                        "Lascia qui i file"
                      ) : (
                        "Trascina qui i file oppure clicca per selezionarli"
                      )}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Formati accettati: PDF, Word, Excel, Immagini.
                      <br />
                      Puoi caricare massimo 35 files sulla pratica.
                    </Typography>
                  </Box>
                </>
              ) : (
                <Typography variant="caption" sx={{ mt: 4 }}>
                  Limite di 35 file raggiunto. Se desideri inserire altri file
                  devi liberare lo spazio cancellando almeno uno di quelli già
                  caricati
                </Typography>
              ))}
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default PracticesDetail;
