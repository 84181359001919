import React, { useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box } from "@mui/system";
import { isMobile } from "react-device-detect";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function createData(
  id,
  business_name,
  created_at
  //colonne tabella
) {
  return {
    id,
    business_name,
    created_at,
    //colonne tabella
  };
}

const UsersList = (promotions, userType, frontendParams, variant) => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { codePractice } = useParams();
  const [clickedIndex, setClickedIndex] = useState(false);
  const [openLoadingBackdrop, setOpenLoadingBackdrop] = useState(false);

  const handlePracticeAssign = async (userCode) => {
    setClickedIndex(userCode);
    setOpenLoadingBackdrop(true);
    try {
      const response = await axiosPrivate.post("/Practices/assign", {
        user_code: userCode,
        practice_code: codePractice,
      });
      if (response) {
        window.alert("Pratica assegnata con successo!");
        window.location.reload();
      }
    } catch (err) {
      setClickedIndex(false);
      setOpenLoadingBackdrop(false);
      console.error("Errore durante la richiesta:", err);
      if (!err?.response) {
        window.alert("Errore. Riprova");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert("Errore. Riprova");
      }
    }
  };

  const renderTableHeader = (header, title) => {
    if (
      frontendParams.order_key === header &&
      frontendParams.order_desc === "true"
    )
      return (
        <React.Fragment>
          <ArrowDropDownIcon />
          <b>{title}</b>
        </React.Fragment>
      );
    else if (
      frontendParams.order_key === header &&
      frontendParams.order_desc === "false"
    )
      return (
        <React.Fragment>
          <ArrowDropUpIcon />
          <b>{title}</b>
        </React.Fragment>
      );
  };

  const rows = [];

  for (let i = 0; i < promotions?.length; i++) {
    rows.push(
      createData(
        promotions[i]["users.code"],
        promotions[i]["user_anagraphics.business_name"],
        promotions[i]["users.created_at"]
      )
    );
  }

  const columns = [
    {
      field: "business_name",
      headerName: "Lista utenti",
      flex: 1,
      sortable: false,
      renderHeader: (param) => {
        return renderTableHeader(param.field, param.colDef.headerName);
      },
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            size="large"
            sx={{
              textAlign: "left",
              justifyContent: "flex-start",
              display: "block",
              height: isMobile && "100%",
              wordBreak: "break-word",
            }}
            onClick={() => {
              if (variant === "practice_assignment")
                window.open(
                  `/area_controller/utenti/periti/${params.row.id}`,
                  "_blank"
                );
              else navigate(`${params.row.id}`);
            }}
          >
            <i>{params.value}</i>
          </Button>
        );
      },
    },
    variant === "practice_assignment"
      ? {
          headerName: "Azione",
          flex: 1,
          minWidth: 150,
          maxWidth: 150,
          sortable: false,
          renderHeader: (param) => {
            return renderTableHeader(param.field, param.colDef.headerName);
          },
          renderCell: (params) => {
            return (
              <>
                <Button
                  fullWidth
                  variant="contained"
                  color="success"
                  onClick={() => {
                    if (
                      window.confirm(
                        `Assegnare la pratica a ${params.row.business_name}?`
                      )
                    )
                      handlePracticeAssign(params.row.id);
                  }}
                  sx={{
                    height: "100%",
                    color: "white",
                    borderRadius: 0,
                  }}
                  disabled={openLoadingBackdrop}
                >
                  Assegna
                  <HowToRegIcon />
                </Button>
                {openLoadingBackdrop && clickedIndex === params.row.id && (
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: 10000,
                    }}
                    open={openLoadingBackdrop}
                  >
                    <CircularProgress color="primary" />
                  </Backdrop>
                )}
              </>
            );
          },
        }
      : {
          field: "created_at",
          headerName: "Data iscrizione",
          flex: 1,
          minWidth: 90,
          maxWidth: 90,
          sortable: false,
          renderHeader: (param) => {
            return renderTableHeader(param.field, param.colDef.headerName);
          },
          renderCell: (params) => {
            const date = new Date(params.value + "Z"); // Forza l'interpretazione UTC

            const formattedDate = date.toLocaleDateString("it-IT");
            const formattedTime = date.toLocaleTimeString("it-IT");

            return (
              <>
                <Box
                  minWidth="85px"
                  sx={{
                    borderLeft: "1px solid #ddd",
                    fontSize: 12,
                    ml: -1,
                    wordBreak: "break-word",
                    height: "100%",
                    alignContent: "center",
                  }}
                >
                  📆 {formattedDate}
                  <br />
                  🕒 {formattedTime}
                </Box>
              </>
            );
          },
        },
  ];
  return { rows: rows, columns: columns };
};

export default UsersList;
